<template src="./CertificateTemplate.html"></template>

<script>
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import certificateTemplateService from "@/services/CertificateTemplate";

export default {
  name: "CertificateTemplate",
  components: {
    ArrobaMedellinSeeker,
  },
  data() {
    return {
      certificatesTemplates: [],
      certificatesTemplatesFilter: [],
      lvlAcademic: "continuingEducation",
    };
  },
  mounted() {
    this.getAllCertificateTemplates();
  },
  methods: {
    async deleteCertificate(id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar una plantilla',
        message: '¿Estás seguro que deseas eliminar esta plantilla?',
        type: 'is-warning is-outlined',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        size: 'is-small',
        onConfirm: async () => {
          try {
            await certificateTemplateService.delete(id);
          } catch (error) {
            console.error(error);
            this.$buefy.toast.open({
              duration: 5000,
              message: error.response?.data?.message ?? "Se presentó un error",
              position: "is-bottom",
              type: "is-danger",
            });
            return null;
          }
          await this.getAllCertificateTemplates();
        }
      })
    },
    createCertificate() {
      this.$router.push("/certificateTemplateEditor");
    },
    async duplicateCertificate(certificateTemplate) {
      try {

        delete certificateTemplate.id;
        delete certificateTemplate.createdAt;
        delete certificateTemplate.updatedAt;
        delete certificateTemplate.authorId;
        delete certificateTemplate.image;
        certificateTemplate.institutionId = certificateTemplate.institution.id
        delete certificateTemplate.institution;
        certificateTemplate.json = JSON.parse(certificateTemplate.json);
        const certificateTem = await certificateTemplateService.create(certificateTemplate);
        if (certificateTem) {
          const url = `/certificateTemplateEditor?id=${certificateTem.id}`;
          this.$router.push(url);
        }
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        return null;
      }
    },
    editCertificate(id) {
      const url = `/certificateTemplateEditor?id=${id}`;
      this.$router.push(url);
    },
    async getAllCertificateTemplates() {
      try {
        const response = await certificateTemplateService.getAll();
        this.certificatesTemplates = response;
        this.certificatesTemplatesFilter = this.certificatesTemplates.filter(
          (certificate) => certificate.lvlAcademic === this.lvlAcademic
        );
      } catch (e) {
        console.log("e", e);
      }
    },
  },
  watch: {
    lvlAcademic() {
      this.certificatesTemplatesFilter = this.certificatesTemplates.filter(
        (certificate) => certificate.lvlAcademic === this.lvlAcademic
      );
    },
  }
};
</script>

<style scoped>
.academic-panel {
  background: linear-gradient(rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)),
    url(../../assets/img/academico-coltejer-v2.jpg) no-repeat fixed;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}
</style>
